import classNames from "classnames";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { palette } from "styles/theme";

type Props = Pick<React.ComponentProps<"div">, "className" | "style"> & {
  /**
   * Contents of a smaller 5-col column on the left side
   */
  columnA: React.ReactElement;

  /**
   * Contents of a larger 7-col column on the right side
   */
  columnB: React.ReactElement;
};

const PADDING = 28;

// Fades content behind, with offset to account for any padding
const Wrapper = styled.div`
  @media (min-width: 768px) {
    position: relative;
    width: auto;
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 40px;
    padding-right: 40px;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: calc(100% - 100px);
      top: 50px;
      background: ${palette.white};
      box-shadow: 0 0 48px 48px ${palette.white};
    }
  }
`;

const Section = styled(Row)`
  max-width: calc(min(1136px, 100% + ${PADDING}px));
  margin-bottom: 24px;
  @media (min-width: 768px) {
    margin-bottom: 76px;
  }
  &&& {
    margin-right: -${PADDING}px;
    margin-left: -${PADDING}px;
  }
`;

const Content = styled(Col)`
  &&& {
    margin-bottom: 24px;
    width: 100%;
    @media (min-width: 768px) {
      margin-bottom: 0;
      padding-left: ${PADDING}px;
      padding-right: ${PADDING}px;
    }
  }
`;

/**
 * Used to put two columns of content next to each other,
 * centering the whole container. The first column is smaller.
 */
const UnevenTwoColumnCenteredSection = ({ columnA, columnB, className, style }: Props) => (
  <Wrapper className={classNames("d-flex flex-column align-items-center", className)} style={style}>
    <Section>
      <Content lg={5}>{columnA}</Content>
      <Content lg={7}>{columnB}</Content>
    </Section>
  </Wrapper>
);

export default UnevenTwoColumnCenteredSection;
