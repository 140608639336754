import RequestBodyCodeExample from "components/docs/code-examples/RequestBodyCodeExample";
import RequestSchemaCodeExample from "components/docs/code-examples/RequestSchemaCodeExample";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import LINKS from "components/docs/navigation/links";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { WarningCard } from "components/docs/shared-components/NoticeCards";
import SectionDivider from "components/docs/shared-components/SectionDivider";
import { StaticFancyPageContainerWithTOC } from "components/docs/shared-components/StaticFancyPageContainer";
import { Link, PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";

const CODE_EXAMPLES_FOLDER = "merge-writes/programmatic/nested";

/**
 * Small width section with padding
 */
const Section = styled(SmallWidthSection).attrs({ className: "mt-9 mb-9" })``;

/**
 * Small width section with smaller padding above
 */
const ShortSection = styled(SmallWidthSection).attrs({ className: "mb-9" })`
  margin-top: 0px;
`;

/**
 * Creates the Guide page for Nested Programmatic Writes with Meta
 */
const ProgrammaticNestedWrites = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => (
  <StaticFancyPageContainerWithTOC
    tableOfContents={[
      {
        text: "Overview",
      },
      {
        text: "Request Schema for Nested Common Models",
      },
    ]}
  >
    <DocsHelmet
      title="Merge Writes: Programmatic Nested Writes with /meta"
      description="Learn how to use Merge Writes' Programmatic Nested Writes with /meta."
    />
    <SmallWidthSection>
      <NavigationScrollTracker>
        <HeaderBar
          title="Programmatic Nested Writes with /meta"
          subtitle={
            <>
              Use <code>/meta</code> to create related Common Model instances (through nesting) with
              standardized or integration / Linked Account-specific fields
            </>
          }
        />
      </NavigationScrollTracker>
    </SmallWidthSection>

    <ShortSection>
      <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Overview">
        <p className="mb-9">
          In an earlier guide to{" "}
          <Link to={LINKS.MERGE_WRITES_RELATED_NESTED.linkTo}>Writing Nested Data</Link>, we
          introduced the concept of{" "}
          <strong>writing new entities nested inside another entity</strong> in a single POST
          request to Merge, thereby establishing a relation.
        </p>
        <WarningCard>
          <p>
            Nested Writes are supported for only a few Common Model pairs. Refer to our guide to{" "}
            <Link to={LINKS.MERGE_WRITES_RELATED_NESTED.linkTo}>Writing Nested Data</Link> for
            support details.
          </p>
        </WarningCard>
        <p className="mt-9 mb-9">
          Just like how <code>/meta</code> can be used to programmatically determine writable fields
          for a Common Model, <code>/meta</code> can also be used to{" "}
          <strong>determine writable fields</strong> for a <strong>nested Common Model</strong>.
        </p>
        <aside>
          Refer to our guide to{" "}
          <Link to={LINKS.MERGE_WRITES_PROGRAMMATIC_INTRO.linkTo}>
            Programmatic Writes with /meta
          </Link>{" "}
          for an introduction to using <code>/meta</code>.
        </aside>
      </NavigationScrollTrackerWithAnchor>
    </ShortSection>
    <SectionDivider />

    <Section>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h3"
        title="Request Schema for Nested Common Models"
      >
        <p>
          In your <code>/meta</code> response, the <code>request_schema</code> object will have
          similar properties whether it is for the <strong>parent</strong> or{" "}
          <strong>nested Common Model</strong>:
        </p>
        <ul className="mb-6">
          <li>Common Model fields</li>
          <li>
            <code>integration_params</code> specifying <strong>integration-specific fields</strong>
          </li>
          <li>
            <code>linked_account_params</code> specifying{" "}
            <strong>Linked Account-specific fields</strong>
          </li>
        </ul>
        <aside>
          Refer to our documentation of{" "}
          <Link to={`${LINKS.MERGE_WRITES_PROGRAMMATIC_INTRO.linkTo}#request_schema`}>
            <code>request_schema</code>
          </Link>{" "}
          for more information.
        </aside>
      </NavigationScrollTrackerWithAnchor>
    </Section>

    <RequestSchemaCodeExample
      folder={CODE_EXAMPLES_FOLDER}
      fileBaseName="request-schema"
      codeBlockName={
        <>
          Example <code>request_schema</code> for <code>Candidate</code> with nested{" "}
          <code>Application</code>
        </>
      }
      explanation={
        <>
          <p>
            <code>Candidates</code> can accept a nested <code>Application</code> in its{" "}
            <code>applications</code> field.
          </p>
          <p>
            A GET request to <code>/candidates/meta/post</code> will show supported fields for this
            nesting in its response:
          </p>
        </>
      }
    />
    <RequestBodyCodeExample folder={CODE_EXAMPLES_FOLDER} fileBaseName="request-body" />

    <Section>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </Section>
  </StaticFancyPageContainerWithTOC>
);

export default ProgrammaticNestedWrites;
