import type { Props as CodeExampleProps } from "components/docs/code-examples/CodeExample";
import CodeExample from "components/docs/code-examples/CodeExample";
import UnevenTwoColumnCenteredSection from "components/docs/sections/UnevenTwoColumnCenteredSection";
import CodeExampleExplainer from "components/docs/shared-components/CodeExampleExplainer";
import React from "react";

type Props = Pick<CodeExampleProps, "folder" | "fileBaseName" | "codeBlockName"> & {
  /**
   * A block of text that appears on the left side to explain the example.
   */
  explanation: string | React.ReactElement;
};

/**
 * Creates a two column section for the request schema example code section
 * that appears on Merge Writes Guides pages.
 */
const RequestSchemaCodeExample = ({ folder, fileBaseName, explanation, codeBlockName }: Props) => (
  <UnevenTwoColumnCenteredSection
    className="pt-9 pb-2 mt-3"
    columnA={
      <>
        <CodeExampleExplainer style={{ marginBottom: "12px" }}>
          Example - Request Schema
        </CodeExampleExplainer>
        {explanation}
      </>
    }
    columnB={
      <CodeExample
        folder={folder}
        codeBlockName={<p>{codeBlockName}</p>}
        fileBaseName={fileBaseName}
        colorScheme="light"
        hasLineNumbers={false}
        isVerticallyPadded={false}
        hideCopyButtonWithHeader
      />
    }
  />
);

export default RequestSchemaCodeExample;
