import type { Props as CodeExampleProps } from "components/docs/code-examples/CodeExample";
import CodeExample from "components/docs/code-examples/CodeExample";
import UnevenTwoColumnCenteredSection from "components/docs/sections/UnevenTwoColumnCenteredSection";
import CodeExampleExplainer from "components/docs/shared-components/CodeExampleExplainer";
import React from "react";

type Props = Pick<CodeExampleProps, "folder" | "fileBaseName">;

/**
 * Creates a two column section for the request schema example code section
 * that appears on Merge Writes Guides pages.
 */
const RequestBodyCodeExample = ({ folder, fileBaseName }: Props) => (
  <UnevenTwoColumnCenteredSection
    className="pt-9"
    columnA={
      <>
        <CodeExampleExplainer style={{ marginBottom: "12px" }}>
          Example - Request Body
        </CodeExampleExplainer>
        <p>
          In accordance with the response from <code>/meta</code> above, the POST request you would
          form might have a body like this:
        </p>
      </>
    }
    columnB={
      <CodeExample
        folder={folder}
        codeBlockName={<p>Example POST request body</p>}
        fileBaseName={fileBaseName}
        hasLineNumbers={false}
        isVerticallyPadded={false}
      />
    }
  />
);

export default RequestBodyCodeExample;
