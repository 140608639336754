import styled from "styled-components";
import { palette } from "styles/theme";

/**
 * A title that appears above a code block (pretitle style). As it's always followed
 * by a regularly padded code block, it has negative padding.
 */
const CodeExampleExplainer = styled.p`
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: ${palette.gray};
  &&& {
    font-size: 10px;
    line-height: 16px;
  }
  &&&& {
    margin-bottom: -8px;
  }
`;

export default CodeExampleExplainer;
